import { useStaticQuery, graphql } from 'gatsby';

export const useNewsFilters = () => {
  const { allNodeNews } = useStaticQuery(
    graphql`
      query NewsFilters {
        allNodeNews {
          nodes {
            relationships {
              field_tags {
                drupal_id
                name
                relationships {
                  parent {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  let newsArray = [];
  let tagIDsArray = [];
  allNodeNews.nodes.forEach((node) => {
    node.relationships.field_tags.forEach((tag) => {
      // We don't want to display the parent tags, only the children
      // here is where we will filter those out.
      if (tag.relationships.parent.length > 0) {
        if (tagIDsArray.indexOf(tag.drupal_id) === -1) {
          tagIDsArray.push(tag.drupal_id);
          newsArray.push({ sort_item: tag.drupal_id, name: tag.name });
        }
      }
    });
  });

  return [
    {
      title: 'News Type',
      field: 'field_tags',
      data: newsArray
    }
  ];
};
