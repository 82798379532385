import React from 'react';
import styles from './listing-layout.module.scss';
import PropTypes from 'prop-types';

const ListingLayout = ({ children }) => (
  <div className={styles.listingLayoutContainer}>{children}</div>
);

ListingLayout.propTypes = {
  children: PropTypes.node
};

export default ListingLayout;
