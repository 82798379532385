import React, { useState } from 'react';
import { useNewsData } from '../hooks/use-news-data';
import { useNewsFilters } from '../hooks/use-news-filters';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Card from '../components/card';
import FilterContentList from '../components/filter-content-list';
import ListingLayout from '../components/listing-layout';

import styles from '../components/listing-layout/listing-layout.module.scss';

const NewsListing = () => {
  const originalNews = useNewsData();
  const filters = useNewsFilters();

  const [newsState, updateNews] = useState(originalNews);

  const imgSizes = '(max-width: 576px) 98vw, (max-width: 795px) 90vw, 344px';
  return (
    <Layout>
      <SEO title="Inside Sandy Springs News" />
      <FilterContentList
        data={originalNews}
        listings={newsState}
        filters={filters}
        bubbleUpState={updateNews}
        style={{ marginBottom: '95px' }}
        desc={'Filter News'}
        allowOldDates={true}
      >
        <ListingLayout>
          {newsState.map((item) => (
            <Card
              key={`news-card-${item.id}`}
              title={item.title}
              type={item.type}
              image={item.largeImage}
              srcset={item?.srcSet?.srcs?.ratio16x9}
              sizes={imgSizes}
              tag={item.tag}
              parentTag={item.parentTag}
              date={item.dateShort}
              summary={item.summary}
              url={item.url}
              className={styles.eventCard}
              cardSummaryOnly={item.cardSummaryOnly}
            />
          ))}
        </ListingLayout>
      </FilterContentList>
    </Layout>
  );
};

export default NewsListing;
